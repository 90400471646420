/*--------------------------------------------------------------
# Hero-2 Section
--------------------------------------------------------------*/

#hero-2 {
  --hero-header-color: var(--main-fill-color);
  --hero-description-color: var(--main-ddd-selector-color);
  --hero-button-text-color: var(--main-accent-color);
  --hero-button-background-color: transparent;
  --hero-button-border-color: var(--main-accent-color);
  --hero-button-hover-text-color: var(--main-fill-color);
  --hero-button-hover-background-color: var(--main-accent-color);
  --hero-button-hover-border-color: var(--main-accent-color);
}

#hero-2 {
  /* This is the component's main CSS for the default BG. (It is not visible because of the image, might not be needed) */
  width: 100%;
  height: calc(100vh);
  background-size: cover;
  overflow: hidden;
}

#hero-2 .container,
#hero-2 .container-fluid {
  padding-top: 84px;
}

#hero-2 h1 {
  /* This is the component's CSS for the Title. */
  margin: 0;
  font-size: 48px;
  font-weight: 600;
  line-height: 64px;
  color: var(--hero-header-color);
}

#hero-2 h2 {
  /* This is the component's CSS for the Text Block. */
  color: var(--hero-description-color);
  margin: 10px 0 0 0;
  font-size: 18px !important;
  font-weight: 400 !important;
}

#hero-2 span {
  /* This is the component's CSS for the Text Block. */
  color: var(--hero-description-color);
  margin: 10px 0 0 0;
  font-size: 18px !important;
  font-weight: 400 !important;
}

#hero-2 .btn-get-started {
  /* This is the component's CSS for CTA button - BG, Border and Text color. */
  width: fit-content;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 5px;
  transition: 0.5s;
  margin-top: 30px;
  background: var(--hero-button-background-color);
  color: var(--hero-button-text-color);
  border: 2px solid var(--hero-button-border-color) !important;
}

#hero-2 .btn-get-started:hover {
  /* This is the component's CSS for CTA button - BG, Border and Text color on-hover. */
  background: var(--hero-button-hover-background-color);
  color: var(--hero-button-hover-text-color);
  border: 2px solid var(--hero-button-hover-broder-color);
}

#hero-2 .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  opacity: 0.85;
  border-radius: 5px;
}

@media (min-width: 1200px) {
  #hero-2 {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero-2 {
    text-align: center;
  }

  #hero-2 .container,
  #hero-2 .container-fluid {
    padding-top: 68px;
  }

  #hero-2 .animated {
    -webkit-animation: none;
    animation: none;
  }

  #hero-2 .hero-img {
    text-align: center;
  }

  #hero-2 .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero-2 h1 {
    font-size: 26px;
    line-height: 36px;
  }

  #hero-2 h2 {
    font-size: 18px;
    line-height: 24px;
  }

  #hero-2 .hero-img img {
    width: 60%;
  }
}

@media (max-width: 575px) {
  #hero-2 .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  #hero-2 .btn-get-started {
    align-self: center;
  }
}
