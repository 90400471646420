@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

#stats3 {
  --color-default: #ffffff;
  --color-background: #000000;
  --color-background-rgb: 0, 0, 0;
  position: relative;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
  padding: 120px 0;
}

#stats3:before {
  content: "";
  background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8));
  position: absolute;
  inset: 0;
  z-index: 2;
}

#stats3:before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 2;
}
#stats3 .container {
  position: relative;
  z-index: 3;
}

#stats3 img {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
}

#stats3 .container {
  position: relative;
  z-index: 3;
}

#stats3 h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-default);
}

.stats3-p {
  color: var(--color-default);
}

#stats3 .cta-btn {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid var(--color-inverse);
  color: var(--color-inverse);
}

#stats3 .cta-btn:hover {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
}

#stats3 .stats-item {
  padding: 30px;
  width: 100%;
}

#stats3 .stats-item span {
  font-size: 48px;
  display: block;
  color: var(--color-default);
  font-weight: 700;
}

.dollar::after {
  content: "M$";
}

.day::after {
  content: " days";
}

.jour::after {
  content: " jours";
}

.percent::after {
  content: "%";
}

.plus::after {
  content: "+";
}

.bottom-right-text p {
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 20px;
  color: #a5abbd;
  z-index: 999;
}
