/*========= Testimonials-3 =========*/
#testimonials-3 {
  --testimonials-background-color: var(--main-background-color);
  --testimonials-header-text-color: var(--main-accent-color);
  --testimonials-elements-background-color: var(--main-fill-color);
  --testimonials-elements-border-shadow1-color: var(--main-background-color);
  --testimonials-elements-border-shadow2-color: var(--main-background-color);
  --testimonials-elements-quotes-background-color: var(--main-accent-color);
  --testimonials-elements-quotes-color: var(--main-fill-color);
  --testimonials-elements-quotes-border-color: var(--main-accent-color);
  --testimonials-elements-quotes-shadow-color: var(--main-accent-color);
  --testimonials-elements-paragraph-text-color: var(--main-general-color);
  --testimonials-elements-name-text-color: var(--main-general-color);
  --testimonials-elements-title-text-color: var(--main-sub-text-color);
  --testimonials-btn-background-color: var(--main-fill-color);
  --testimonials-btn-icon-color: var(--main-sub-text-color);
  --testimonials-btn-hover-background-color: var(--main-accent-color);
  --testimonials-btn-icon-hover-color: var(--main-fill-color);
  --testimonials-btn-shadow-color: var(--main-ddd-selector-color);
  --testimonials-title-color: var(--main-titles-color);
}

.testimonials-3-testimonials-container {
  /* margin-top: 3rem !important;
    margin-bottom: 3rem !important;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; */
  background-color: var(--testimonials-background-color);
  max-width: none !important;
}

.testimonials-3-testimonials-div1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  /* display: flex; */
  /* flex-direction: column; */
}

.testimonials-3-testimonials-h2 {
  max-width: 300px;
  text-align: left !important;
  padding-top: 0.5rem !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 800;
  line-height: 1.3;
  color: var(--testimonials-title-color);
}

.testimonials-3-testimonials-br1 {
  display: inline !important;
}

.testimonials-3-testimonials-div3 {
  padding-top: 1.5rem !important;
  margin-top: 3rem !important;
  justify-content: flex-start !important;
  padding-bottom: 1.5rem !important;
  margin-bottom: 0.5rem !important;
  display: flex !important;
}

.testimonials-3-testimonials-slider {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.testimonials-3-testimonials-container .swiper-slide {
  width: 322px;
  margin-right: 8px;
  padding-top: 1.5rem !important;
  height: auto !important;
  background-color: var(--testimonials-elements-background-color);
}

.testimonials-3-testimonials-figure1 {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
  flex-direction: column !important;
  height: 100% !important;
  display: flex !important;
}

.testimonials-3-testimonials-div5 {
  padding-top: 1.5rem !important;
  height: 100% !important;
  border: 0 !important;
  position: relative !important;
  box-shadow: 0 0.275rem 0.75rem -0.0625rem var(--testimonials-elements-border-shadow1-color), 0 0.125rem 0.4rem -0.0625rem var(--testimonials-elements-border-shadow2-color) !important;
}

.testimonials-3-testimonials-container .shadow-primary {
  box-shadow: 0 0.5rem 1.125rem -0.5rem var(--testimonials-elements-quotes-shadow-color) !important;
}

.testimonials-3-testimonials-span1 {
  pointer-events: none !important;
  margin-left: 1.5rem !important;
  transform: translateY(-50%) !important;
  left: 0 !important;
  top: 0 !important;
  position: absolute !important;
  color: var(--testimonials-elements-quotes-color);
  background-color: var(--testimonials-elements-quotes-background-color);
  border-color: var(--testimonials-elements-quotes-border-color);
}

.testimonials-3-testimonials-blockquote1 {
  padding-bottom: 1rem !important;
  margin-bottom: 0 !important;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

.testimonials-3-testimonials-p1 {
  margin-bottom: 0 !important;
  color: var(--testimonials-elements-paragraph-text-color);
}

.testimonials-3-testimonials-figcaption1 {
  padding-left: 1.5rem !important;
  padding-top: 1.5rem !important;
  align-items: center !important;
  display: flex !important;
}

.testimonials-3-testimonials-div6 {
  padding-left: 1rem !important;
}

.testimonials-3-testimonials-h6 {
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  margin-bottom: 0 !important;
  color: var(--testimonials-elements-name-text-color);
}

.testimonials-3-testimonials-span2 {
  font-size: 0.75rem !important;
  color: var(--testimonials-elements-title-text-color);
}

.testimonials-3-testimonials-container .testimonials-arrows {
  font-size: 1.25em;
}

.testimonials-3-testimonials-container .testimonials-3-testimonials-btn {
  width: 2.75rem;
  height: 2.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem !important;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border: 0;
  border-radius: 50%;
  background-color: var(--testimonials-btn-background-color);
  color: var(--testimonials-btn-icon-color) !important;
  font-size: 1rem;
  box-shadow: 0 0.275rem 0.75rem -0.0625rem var(--testimonials-btn-shadow-color);
}

.testimonials-3-testimonials-btn-prev:focus,
.testimonials-3-testimonials-btn-next:focus {
  background-color: var(--testimonials-btn-background-color);
}
.testimonials-3-testimonials-btn-prev.bg-white,
.testimonials-3-testimonials-btn-next.bg-white {
  color: var(--testimonials-btn-icon-color) !important;
}
.testimonials-3-testimonials-btn-prev:not(.bg-transparent):hover,
.testimonials-3-testimonials-btn-next:not(.bg-transparent):hover {
  background-color: var(--testimonials-btn-hover-background-color) !important;
  color: var(--testimonials-btn-icon-hover-color) !important;
}

.testimonials-3-testimonials-btn-next {
  margin-left: 0.5rem !important;
}

.testimonials-3-testimonials-div2 {
  flex: 0 0 auto;
  width: 25%;
}

.testimonials-3-testimonials-div4 {
  flex: 0 0 auto;
  width: 75%;
}

/* Mobile Settings */
@media (max-width: 768px) {
  .testimonials-3-testimonials-div2 {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .testimonials-3-testimonials-div4 {
    width: 100%;
  }
  .testimonials-3-testimonials-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (min-width: 992px) {
  .testimonials-3-testimonials-container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (min-width: 1200px) {
  .testimonials-3-testimonials-container {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (min-width: 1400px) {
  .testimonials-3-testimonials-container {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: 768px) {
  .testimonials-3-testimonials-div3 {
    display: none !important;
  }
}
