#cta-2 {
  --cta-2-background-color: var(--main-fill-color);
  /* --about-header-color: var(--main-accent-color);
  --about-paragraph-color: var(--main-general-color);
  --about-bulletpoint-color: var(--main-accent-color);
  --about-bulletpoint-text-color: var(--main-sub-text-color); */
}

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

#cta2 {
  padding: 130px 0;
  position: relative;
  -webkit-clip-path: inset(0);
  clip-path: inset(0);
}

#cta2 img {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
}

#cta2:before {
  content: "";
  background: linear-gradient(rgba(14, 29, 52, 0.6), rgba(14, 29, 52, 0.8));
  position: absolute;
  inset: 0;
  z-index: 2;
}

#cta2 .container {
  position: relative;
  z-index: 3;
}

#cta2 h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--main-fill-color);
}

#cta2 p {
  color: var(--main-fill-color);
}

#cta2 .cta-btn {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid var(--main-fill-color);
  color: var(--main-fill-color);
}

#cta2 .cta-btn:hover {
  background: var(--main-accent-color);
  border: 2px solid var(--main-accent-color);
}
