/* fix qa that are not collapsing properly */

/* The arrow btn icon colors cannot be changed from the variables, check the image for "fill='' and change the value there." */
#faq-2 {
  --faq-background-color: var(--main-fill-color);
  --faq-header-text-color: var(--main-accent-color);
  --faq-paragraph-text-color: var(--main-sub-text-color);
  --faq-boxes-element-background-color: var(--main-background-color);
  --faq-boxes-element-border-color: var(--main-ddd-selector-color);
  --faq-boxes-element-text-color: var(--main-general-color);
  --faq-phone-element-icon-color: var(--main-accent-color);
  --faq-phone-element-link-color: var(--main-accent-color);
  --faq-phone-element-link-hover-color: var(--main-accent-color);
  --faq-chat-element-icon-color: var(--main-accent-color);
  --faq-chat-element-link-color: var(--main-accent-color);
  --faq-chat-element-link-hover-color: var(--main-accent-color);
  --faq-question-header-background-color: var(--main-fill-color);
  --faq-question-header-text-color: var(--main-general-color);
  --faq-question-selected-header-background-color: var(--main-dark-accent-color);
  --faq-question-selected-header-text-color: var(--main-fill-color);
  --faq-question-selected-box-shadow-color: var(--main-dark-accent-color);
  --faq-question-body-background-color: var(--main-fill-color);
  --faq-question-body-text-color: var(--main-sub-text-color);
  --faq-question-item-border-color: var(--main-ddd-selector-color);
  --faq-question-item-shadow-color: #0b0f190f;
  --faq-arrow-btn-background-color: var(--main-background-color);
  --faq-arrow-btn-selected-background-color: var(--main-accent-color);
  --faq-arrow-btn-shadow-color: var(--main-accent-color);
  --faq-title-color: var(--main-titles-color);
}

hr {
  color: var(--main-accent-color); /* Hex color code for bright blue */
}

.faq-2-section {
  --opp-gutter-x: 1.5rem;
  --opp-gutter-y: 0;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  /* padding-top: 3rem;
  padding-bottom: 3rem; */
  background-color: var(--faq-background-color);
  max-width: none !important;
}

@media (min-width: 500px) {
  .faq-2-section {
    max-width: 97%;
  }
}
@media (min-width: 768px) {
  .faq-2-section {
    max-width: 95%;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 992px) {
  .faq-2-section {
    max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .faq-2-section {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (min-width: 1400px) {
  .faq-2-section {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.faq-2-row {
  --opp-gutter-x: 1.5rem;
  --opp-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.faq-2-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-top: 0;
}

.faq-2-left {
  text-align: center;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1200px) {
  .faq-2-left {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

@media (min-width: 992px) {
  .faq-2-left {
    flex: 0 0 auto;
    width: 33.33333333%;
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.faq-2-left-header {
  padding-bottom: 1rem;
  margin-bottom: 0.25rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 800;
  line-height: 1.3;
  font-size: calc(1.325rem + 0.9vw);
  color: var(--faq-title-color);
}

@media (min-width: 992px) {
  .faq-2-left-header {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1200px) {
  .faq-2-left-header {
    font-size: 2rem;
  }
}

.faq-2-left-description {
  font-size: 1.125rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  color: var(--faq-paragraph-text-color);
}

@media (min-width: 992px) {
  .faq-2-left-description {
    margin-bottom: 1rem;
  }
}

.faq-2-left-contact-row {
  margin-top: 1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  --opp-gutter-x: 1.5rem;
  --opp-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.faq-2-left-contact-row > * {
  flex: 0 0 auto;
  width: 100%;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

@media (min-width: 500px) {
  .faq-2-left-contact-row > * {
    flex: 0 0 auto;
    width: 50%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.faq-2-left-column {
  flex: 1 0 0%;
}

.faq-2-left-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid var(--faq-boxes-element-border-color);
  border-radius: 0.5rem;
}

.faq-2-left-card:not(.bg-transparent) {
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.faq-2-left-card:not(.bg-transparent):hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.275rem 0.75rem -0.0625rem var(--faq-question-item-shadow-color);
}
.faq-2-left-card:not(.bg-transparent).shadow-sm:hover {
  box-shadow: 0 0.275rem 1.25rem var(--faq-question-item-shadow-color);
}

.faq-2-left-card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
  padding-bottom: 1rem;
  background-color: var(--faq-boxes-element-background-color);
  color: var(--faq-boxes-element-text-color);
}

.faq-2-left-phone-icon {
  display: block;
  font-size: calc(1.325rem + 0.9vw);
  color: var(--faq-phone-element-icon-color);
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.faq-2-left-message-icon {
  display: block;
  font-size: calc(1.325rem + 0.9vw);
  color: var(--faq-chat-element-icon-color);
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.faq-2-left-btn {
  text-decoration: underline;
  display: inline-block;
  padding: 0.625rem 1.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.6;
  color: var(--faq-phone-element-link-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  background-color: transparent;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
}

.faq-2-left-btn:hover {
  color: var(--faq-phone-element-link-hover-color);
}

.faq-2-green {
  color: var(--faq-chat-element-link-color);
}

.faq-2-green:hover {
  color: var(--faq-chat-element-link-hover-color);
}

.faq-2-left-text {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.faq-2-left-btn::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
@media (min-width: 992px) {
  .faq-2-right {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}

@media (min-width: 992px) {
  .faq-2-right {
    margin-left: 5%;
  }
}

.faq-2-right {
  /* display: flex; */
  align-items: center;
  margin: auto;
}

.faq-2-right-accordion-item {
  list-style: none;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0.275rem 0.75rem -0.0625rem var(--faq-question-item-shadow-color);
  margin-bottom: 1rem;
  /* margin-right: -45rem; */
  color: var(--faq-question-body-text-color);
  background-color: var(--faq-question-body-background-color);
  border: 1px solid var(--faq-question-item-border-color);
}
.faq-2-right-accordion-item:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.faq-2-right-accordion-item .faq-2-right-accordion-button {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.faq-2-right-accordion-item:not(:first-of-type) {
  border-top: 0;
}
.faq-2-right-accordion-item:last-of-type {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.faq-2-right-accordion-item .accordion-button.collapsed {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.faq-2-right-accordion-item .faq-2-right-accordion-collapse {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.faq-2-right-accordion-button {
  border-radius: 0.5rem;
  box-shadow: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 20px;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: none;
  font-weight: 600;
  color: var(--faq-question-header-text-color);
  background-color: var(--faq-question-header-background-color);
}

.faq-2-right-accordion-button::after {
  width: 2.25rem;
  height: 2.25rem;
  background-position: center;
  background-color: var(--faq-arrow-btn-background-color);
  transition: all 0.35s;
  border-radius: 50%;
}
.faq-2-right-accordion-button:not(.collapsed)::after {
  background-color: var(--faq-arrow-btn-selected-background-color);
  box-shadow: 0 -0.5rem 1.125rem -0.5rem var(--faq-arrow-btn-shadow-color);
}

@media (prefers-reduced-motion: reduce) {
  .faq-2-right-accordion-button {
    transition: none;
  }
}

.faq-2-right-accordion-button:not(.collapsed) {
  color: var(--faq-question-selected-header-text-color);
  background-color: var(--faq-question-selected-header-background-color);
  box-shadow: inset 0 -1px 0 var(--faq-question-selected-box-shadow-color);
}
.faq-2-right-accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23fff'%3e%3cpath d='M225.813 48.907L128 146.72 30.187 48.907 0 79.093l128 128 128-128z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.faq-2-right-accordion-button::after {
  flex-shrink: 0;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256' fill='%23565973'%3e%3cpath d='M225.813 48.907L128 146.72 30.187 48.907 0 79.093l128 128 128-128z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 0.625rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .faq-2-right-accordion-button::after {
    transition: none;
  }
}
.faq-2-right-accordion-button:hover {
  z-index: 2;
}
.faq-2-right-accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: unset;
}

.faq-2-right-accordion-body {
  padding: 1rem 1.5rem;
  font-size: 0.875rem !important;
  padding-top: 0 !important;
}

@media (max-width: 992px) {
  .faq-2-accrodion-items {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.faq-2-accrodion-items {
  padding-left: 0;
}

.faq-2-p {
  font-size: 16px;
  font-weight: 500;
}
