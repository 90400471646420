/*--------------------------------------------------------------
# Realtor Not found
--------------------------------------------------------------*/

#realtor-notfound {
  --error-background-color: var(--main-background-color);
  --error-header-color: var(--main-dark-accent-color);
  --error-description-color: var(--main-sub-text-color);
  --error-button-background-color: var(--main-accent-color);
  --error-button-border-color: var(--main-accent-color);
  --error-button-text-color: var(--main-fill-color);
  --error-button-hover-text-color: var(--main-accent-color);
  --error-button-hover-border-color: var(--main-accent-color);
  --error-button-hover-background-color: var(--main-fill-color);
  --error-social-background-color: var(--main-sub-text-color);
  --error-social-hover-background-color: var(--main-accent-color);
  --error-paragraph-text-color: var(--main-general-color);

  color: var(--error-paragraph-text-color);
}

.agent-avatar {
  max-height: 600px;
}

#realtor-notfound {
  width: 100%;
  height: 100vh;
  background: var(--error-background-color);
  border-bottom: 2px solid var(--error-background-color);
  text-align: center;
}

#realtor-notfound .social-links a {
  font-size: 25px;
  color: var(--error-social-background-color);
  padding: 25px 0 10px 10px;
  margin-right: 15px;
  transition: 0.5s;
}

#realtor-notfound .social-links a:hover {
  color: var(--error-social-hover-background-color);
  text-decoration: none;
}

#realtor-notfound .textAlignment-left {
  text-align: left;
  padding: 5% 0px 0px 0px;
}

#realtor-notfound h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  width: 60%;
  color: var(--error-header-color);
}

#realtor-notfound h2 {
  margin: 15px 0 0 0;
  font-size: 24px;
  color: var(--error-description-color);
}

#realtor-notfound .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: block;
  padding: 8px 32px 10px 32px;
  margin-top: 25px;
  border-radius: 5px;
  transition: 0.5s;
  color: var(--error-button-text-color);
  border: 2px solid var(--error-button-border-color);
  background: var(--error-button-background-color);
}

#realtor-notfound .btn-get-started:hover {
  color: var(--error-button-hover-text-color);
  border: 2px solid var(--error-button-hover-border-color);
  background: var(--error-button-hover-background-color);
}

#realtor-notfound .realtor-notfound-img {
  max-width: 60%;
  margin-top: 40px;
}

@media (max-width: 992px) {
  #realtor-notfound h1 {
    font-size: 36px;
    line-height: 42px;
    width: 100%;
  }

  #realtor-notfound h2 {
    font-size: 20px;
    line-height: 24px;
  }

  #realtor-notfound .realtor-notfound-img {
    max-width: 90%;
  }
}

@media (max-height: 768px) {
  #realtor-notfound {
    height: auto;
  }
}

@media only screen and (hover: none) and (pointer: coarse) and (min-width: 390px) {
  #realtor-notfound {
    padding-top: 70vh;
    padding-bottom: 70vh;
  }
}

@media only screen and (hover: none) and (pointer: coarse) and (min-width: 760px) {
  #realtor-notfound {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  #realtor-notfound {
    padding-left: 10px;
    padding-right: 10px;

    .btn-get-started {
      margin-bottom: 10px;
    }
  }
}

.not-found-section {
  margin-top: 2rem;
}
